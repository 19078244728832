import React, { useEffect } from 'react';
import s from './SpeedDialSocial.module.scss';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { ReactComponent as WhatsAppIcon } from './../../assets/icon/whatsapp_icon.svg';
import { ReactComponent as TelegramIcon } from './../../assets/icon/telegram_icon.svg';
import { ReactComponent as ContactUsIcon } from './../../assets/icon/icon-contact-us.svg';
import { useAppSelector } from '../../store/hooks';

const SpeedDialSocial = () => {
    const { info } = useAppSelector(state => state.info)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const actions = [
        {
            icon: <WhatsAppIcon />,
            name: 'WhatsApp',
            url: `https://wa.me/${info?.contacts.whatsapp}`
        },
        {
            icon: <TelegramIcon />,
            name: 'Telegram',
            url: `https://t.me/${info?.contacts.telegram}`
        },
    ];

    return (
        <SpeedDial
            className={s.box}
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'fixed', bottom: 55, right: 130 }}
            icon={<ContactUsIcon className={s.icon} />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={
                        <a
                            onClick={handleClose}
                            href={action.url}
                            target="_blank"
                            className={s.link}
                        >
                            {action.icon}
                        </a>
                    }
                    tooltipTitle={action.name}
                    tooltipOpen

                />
            ))}
        </SpeedDial>
    );
};

export default SpeedDialSocial;
