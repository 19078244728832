import React, { FC, useEffect, useState } from 'react';
import s from './Products.module.scss'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { scrollToTop } from '../../utils';
import { fetchByCatalogProducts } from '../../store/slices/productsSlice';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Pagination, PaginationItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const Products: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { id, name } = useParams()
    const [page, setPage] = useState(searchParams.get('page') || 1)
    const dispatch = useAppDispatch()
    const { loading, products, error, pagination } = useAppSelector(state => state.products)

    const changePage = (page: number) => {
        setPage(page)
        setSearchParams({ page: `${page}` })
    }


    useEffect(() => {
        const p = searchParams.get('page')
        if (p) {
            setPage(+p)
        }
    }, [searchParams])

    useEffect(() => {
        if (id) {
            scrollToTop()
            dispatch(fetchByCatalogProducts({ id, page: `${page}` }))
        }
    }, [dispatch, page, id])


    return (
        <div>
            <Helmet>
                <meta property="og:title" content={`${name} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`${name} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/catalog/products/${id}/${name}`} />
                <title>
                    {`${name}`} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <div className={s.links}>
                <div className='container'>
                    <div className={s.inner_links_container}>
                        <Link to={`/catalog`}>Каталог</Link>
                        |
                        <h2 className={s.catalog_name}>{name}</h2>
                    </div>
                </div>
            </div>
            {
                error ?
                    <h2 className={s.error}>{error}</h2>
                    :
                    <>
                        <h2 className={`title ${s.title}`}>{name}</h2>
                        <div className='container'>
                            <div className={s.wrapper}>
                                {
                                    products.length > 0 ?
                                        products.map(el => <ProductCard data={el} key={el.id} />)
                                        :
                                        <h2 className={s.empty}>Товары в этой категории на данный момент не найдены!</h2>
                                }
                            </div>
                            <div className={s.pagination_wrapper}>
                                {
                                    pagination && products.length > 0 &&
                                    <Pagination
                                        page={+page}
                                        onChange={(e, page) => changePage(page)}
                                        count={Math.ceil(pagination?.total / pagination?.per_page)}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                {...item}
                                            />
                                        )}
                                    />
                                }
                            </div>
                        </div>
                    </>
            }
        </div>
    );
};

export default Products;