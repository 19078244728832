import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IInfoAndHome, IPagination, IProduct, IProductsByCatalog, IProductsData } from '../types'
import { storesApi } from '../../axios'

type ProductsState = {
    loading: boolean
    error: null | string
    products: IProduct[]
    pagination: null | IPagination
    detail: IProduct | null
}

const initialState: ProductsState = {
    error: null,
    loading: false,
    products: [],
    pagination: null,
    detail: null
}

export const fetchByCatalogProducts = createAsyncThunk<IProductsData, IProductsByCatalog, { rejectValue: string }>(
    'products/fetchByCatalogProducts',
    async (data, { rejectWithValue }) => {
        const res = await storesApi.getProductsByCatalog(data)
        console.log(res)

        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data as IProductsData
    }
)

export const fetchByCatalogProductDetail = createAsyncThunk<IProduct, number, { rejectValue: string }>(
    'products/fetchByCatalogProductDetail',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getProductById(id)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data as IProduct
    }
)




const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearDetail(state) {
            state.detail = null
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByCatalogProducts.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByCatalogProducts.fulfilled, (state, action) => {
            state.products = action.payload.data
            state.pagination = action.payload.pagination
            state.loading = false
        })
        addCase(fetchByCatalogProducts.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })
        // ===========================================================
        addCase(fetchByCatalogProductDetail.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByCatalogProductDetail.fulfilled, (state, action) => {
            state.detail = action.payload
            state.loading = false
        })
        addCase(fetchByCatalogProductDetail.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })
    },
})

export const { clearDetail } = productsSlice.actions

export default productsSlice.reducer
